<template>
<!--Header-->
<div class= "button">
  <div class="mb-20">
    
    </div>
<!--Content-->

<div class ="object-top gap-0 flex flex-col items-center justify-center " > 
<img  class= "max-w-50%" src = "/images/PU Flier Final.jpg">
</div>


<div class= "grid grid-cols-6 gap-0">
   <div class= "box-border mb-3 border-0">
</div>

     

  <div class ="box-border mb-2 border-1 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center font-sans text-lg text-black scroll:text-pink bg-turq">
                <h class="text-xl text-bold text-indigo-900"> We are a <span class="text-indigo-500"><a href="https://breakingground.org.uk/"> Community Led Housing Project </a></span>
                <br/> <br/></h>
                <p>    <!-- on scroll turn text tex-grey-700 -->
                We are Persons Unknown Housing Co-operative and our aim is to provide quality, sustainable, affordable, environmentally friendly, self-managed housing for our co-op members and to the wider local community in Toxteth, Liverpool. <br/> <br/> We aim to achieve this by taking disused land, turned down by developers, and bringing it into co-operative ownership, securing accessible high quality housing for generations to come. And bringing more joy to our community by reusing decaying brownfield sites.
                <br/> <br/></p>
               
                <p>
                Our aim is to adopt a development approach that can be replicated by other community led housing groups in order to achieve affordable homes with little or no recourse to mainstream public funds. <br/> <br/> We aspire to also succeed where other older co-operatives have failed, and use our future cash reserves to invest in a new generation of co-ops in the future.
                We are fully mutual and all members have a regular active role in the running of the co-op. If you are interested in learning new skills, and changing the world for the better and saving communities from the housing crisis, get in touch! We are a sustainable, socially impactful solution.
                <br/> <br/> </p>
                <br>
                <p>
                We are interested in working with other housing co-operatives and registered providers in order to develop our housing scheme. We are currently working with a team of professionals to ensure our financial viability.
                Alongside grants and loans, we will issue loanstock when our feasibility study is complete.
                <br/> <br/>
                 You can buy loanstock from us! Choose your own interest rate, and payback period to make an ethical investment.
                 <br/> <br/></p>
  </div>

   <div class ="box-border mb-3 border-1 p-10 rounded-md col-start-1 col-span-6 flex flex-col items-center justify-center text-center text-lg bg-pink">
                <h class="text-xl text-bold text-indigo-900"> Our Professional Team So Far
                <br/> <br/></h>
                <p>    
                We have had so much help so far in order to converse with the council and gather a design team together. <span class="text-indigo-500"><a href="https://breakingground.org.uk/">The Breaking Ground Community Led Housing Hub</a></span> have helped us so much along the way. Through them we found our project manager, who has introduced us to new perspectives, and lots of new spreadsheets. She has given us the confidence to dive into things<br/> <br/></p>
               <p>
               <span class="text-indigo-500"><a href="http://urbed.coop/">URBED</a></span> have been working with us to envision what's possible on the site. They are there for us when we want to geek out over environmentally friendly buuilding ideas. They have put in so much work so far, helping us get quotes from quantity surveyors and engineers and helped with the vital parts of the feasibility study so far. <br/> <br/> </p>
                <br>
                <p>
                 We have been a member of <span class="text-indigo-500"><a href="www.radicalroutes.org.uk">Radical Routes Co-op</a></span> since before our formation. They helped us register as a co-op, write a business plan and are just there for us generally for all things housing co-op. Through them we met our fantastic accountants and financial modellers <span class="text-indigo-500"><a href="https://www.catalystcollective.org/">Catalyst Collective</a></span>. In turn, we have a work commitment to Radical Routes, as its a mutual aid organisation. <br/> <br/>
                 You can buy loanstock from us! Choose your own interest rate, and payback period to make an ethical investment.
                 <br/> <br/></p>
  </div>
</div>
</div>

</template>

<script>
import {onMounted, ref, watch} from "vue";
import store from "@/store";


export default {
  setup() {
    let questionsData = ref(null)
    let questionData = ref(null)
    let currentQuestionId = ref(0)
    let currentQuestionType = ref("A")
    let isMoreQuestions = ref(true)

    function getSpecificQuestionData() {
      Object.values(questionsData.value).forEach(question => {
        if (question.id == currentQuestionId.value) {
          questionData.value = question.questionType.filter(questionDetail => questionDetail.option == currentQuestionType.value)
          console.log(questionData.value)
        }
      })
    }

    function getNewQuestionData(buttonName) {
      store.dispatch("saveResponse", buttonName)
      currentQuestionId.value++
      currentQuestionType.value = buttonName
      // pull another question only if there's more in store
      if (currentQuestionId.value < questionsData.value.length) {
        getSpecificQuestionData()
      } else {
        isMoreQuestions.value = false
      }
    }

    function restart() {
      window.location.reload()
    }

    onMounted(() => {
      //console.log(store.getters.enteredWeb)
      //store.dispatch("setEntered", "true")
      //console.log(store.getters.enteredWeb)
      console.log(store.getters.questions)
      questionsData.value = store.getters.questions
      getSpecificQuestionData()
    })

    watch(currentQuestionId, () => {
      console.log(currentQuestionId)
      console.log(store.getters.responses)
    })

    watch(currentQuestionType, () => {
      console.log(currentQuestionType)
    })

    return {
      questionsData,
      questionData,
      currentQuestionId,
      currentQuestionType,
      getNewQuestionData,
      isMoreQuestions,
      restart
    }
  }
}
</script>

<style scoped>

</style>